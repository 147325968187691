import React, { useContext, useEffect, useRef, useState } from 'react';
import { Input, Form, Select, Table, Button, Radio, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
const { Option } = Select;
const props = {
    action: '//jsonplaceholder.typicode.com/posts/',
    listType: 'picture',
    previewFile(file) {
        console.log('Your upload file:', file);
        // Your process logic. Here we just mock to the same file
        return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
            method: 'POST',
            body: file,
        })
            .then((res) => res.json())
            .then(({ thumbnail }) => thumbnail);
    },
};


const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

const NewEstimates = () => {
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const [dataSource, setDataSource] = useState([
        {
            key: '0',
            ProductsDetails: 'Type or click to select an item.',
            HSNCode:'0235645',
            Quantity: '1.00',
            Rate: '0.00',
            GST:'00%',
            TDS:'00%',
            Amount: '0.00',
        },
    ]);
    const [count, setCount] = useState(2);
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };
    const defaultColumns = [
        {
            title: 'Products Details',
            dataIndex: 'ProductsDetails',
            editable: true,
            align: 'center',
            width: '40%',
        },
        {
            title: 'HSN Code',
            dataIndex: 'HSNCode',
            align: 'center',
            editable: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            align: 'center',
            editable: true,
        },
        {
            title: 'Rate',
            dataIndex: 'Rate',
            align: 'center',
            editable: true,
        },
        {
            title: 'GST',
            dataIndex: 'GST',
            align: 'center',
            editable: true,
        },
        {
            title: 'TDS',
            dataIndex: 'TDS',
            align: 'center',
            editable: true,
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            align: 'center',
            // colSpan: 2,
            editable: true,
            width: '40%',
        },
    ];
    const handleAdd = () => {
        const newData = {
            key: count,
            name: `Edward King ${count}`,
            age: '32',
            address: `London, Park Lane no. ${count}`,
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const selectAfter = (
        <Select
            defaultValue="USD"
            style={{
                width: 60,
            }}
        >
            <Option value="USD">$</Option>
            <Option value="EUR">€</Option>
            <Option value="GBP">£</Option>
            <Option value="CNY">¥</Option>
        </Select>
    );

    return (
        <>
            <div className="main-container">
                <div className="container mb-5">
                    <div className="row mb-4">
                         <div className="col-lg-12">
                            <div className="card table-card border-0">
                                <div className="search-box d-flex justify-content-between align-items-center mt-4">
                                    <div className='d-flex align-items-center'>
                                        <h1 className='heading'>Estimate</h1>
                                        <div className="comHeading px-3">
                                            <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                                <li className="mx-1" >
                                                    <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                                </li>
                                                <li className="mx-1">
                                                    <Link to="#">Sales<i className="fas fa-chevron-right ms-2"></i></Link>
                                                </li>
                                                <li className="mx-1">
                                                    <Link to="#">Estimate</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body px-3 mb-5">
                            <div className="row card py-3">
                                <div className="col-lg-12 table-card px-4">
                                    <div className="head">
                                        <h1 className='h5 mb-0'>Select Customer</h1>
                                    </div>
                                </div>
                                <div className="row px-4 mb-2 me-0 pe-0 customer-card d-flex justify-content-between align-items-center">
                                    <div className="col-lg-3">
                                        <div className="card dash-card1">
                                            <div className="card-body">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    <div className='dash-icon1'>
                                                        <img src="./images/DD.svg" alt="DD" />
                                                    </div>
                                                    <div>
                                                        <h1>Customer Name</h1>
                                                        <p>Company Name</p>
                                                    </div>
                                                    <div className='my-2 ms-auto pe-1'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="card dash-card1">
                                            <div className="card-body">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    <div className='dash-icon1'>
                                                        <i class="fa-solid fa-location-dot"></i>
                                                    </div>
                                                    <div>
                                                        <h1>Address</h1>
                                                        <p>A-2045,Scincecity</p>
                                                    </div>
                                                    <div className='my-2 ms-auto pe-1'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="card dash-card1">
                                            <div className="card-body">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    <div className='dash-icon1'>
                                                        <i class="fa-solid fa-envelope"></i>
                                                    </div>
                                                    <div>
                                                        <h1>Email</h1>
                                                        <p>Abc12@gmail.com</p>
                                                    </div>
                                                    <div className='my-2 ms-auto pe-1'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="card dash-card1">
                                            <div className="card-body">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    <div className='dash-icon1'>
                                                        <i class="fa-solid fa-phone"></i>
                                                    </div>
                                                    <div>
                                                        <h1>Mobile</h1>
                                                        <p>+91 2345689552</p>
                                                    </div>
                                                    <div className='my-2 ms-auto pe-1'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 table-card px-4">
                                    <div className="head">
                                        <h1 className='h5 mb-0'>Estimate</h1>
                                    </div>
                                </div>
                                <div className="row ps-4 d-flex justify-content-between align-items-center">
                                    <div className="col-lg-3 pe-0">
                                        <label>Estimate Number</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Estimate Date"
                                            Placeholder="#021320"
                                        />

                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <label>Ref Number</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Ref Number"
                                            Placeholder="Order Number"
                                        />
                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <label>Frome Date</label>
                                        <DatePicker placeholder="21/12/2022" onChange={onChange} className="w-100" />
                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <label>To Date</label>
                                        <DatePicker placeholder="21/12/2022" onChange={onChange} className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2 px-4 table-card">
                                    <div className="head">
                                        <h1 className='h5 mb-0'>Products Description</h1>
                                    </div>
                                </div>
                                <div className="row  mx-0 px-0">
                                    <div className="col-lg-12 mx-0 px-0">
                                        <div className='invoice-tab'>
                                            <Table
                                                components={components}
                                                scroll={{ x: true }}
                                                rowClassName={() => 'editable-row'}
                                                bordered
                                                dataSource={dataSource}
                                                columns={columns}
                                                pagination={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row mx-3 mt-3">
                                    <div className="col-lg-3 ps-0">
                                        <Button
                                            onClick={handleAdd}
                                            type="primary"
                                            style={{
                                                marginBottom: 16,
                                            }}
                                        >
                                            + Add another line
                                        </Button></div>
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-6 receipt px-2">
                                        <div className="row mx-auto mb-3">
                                            <div className="col-lg-4 col-md-4">
                                                Sub Total
                                            </div>
                                            <div className="col-lg-8 text-end col-md-8">
                                                0.00
                                            </div>
                                        </div>

                                        <div className="row mx-auto mb-3">
                                            <div className="col-lg-4 col-md-4 pe-0">
                                                Discount
                                            </div>
                                            <div className="col-lg-5 col-md-5 ps-0">
                                               <Input addonAfter={selectAfter} defaultValue="0" />
                                            </div>
                                            <div className="col-lg-3 col-md-3 text-end">
                                                0.00
                                            </div>
                                        </div>

                                        <div className="row  mx-auto mb-3 d-flex">
                                            <div className="col-lg-4 col-md-4 pe-0">
                                                <Radio.Group onChange={onChange} value={value}>
                                                    <Radio value={1}>TDS</Radio>
                                                    <Radio value={2}>TCS</Radio>
                                                </Radio.Group>
                                            </div>
                                            <div className="col-lg-5 col-md-5 ps-0">
                                                <select class="form-select" aria-label="Default select example">
                                                    <option value="1">Select a Text</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3  col-md-3 text-end">
                                                0.00
                                            </div>
                                        </div>

                                        <div className="row  mx-auto mb-3">
                                            <div className="col-lg-4 col-md-4">
                                                <Input placeholder='Adjustment' />
                                            </div>
                                            <div className="col-lg-5 col-md-5 ps-0">
                                                <Input />
                                            </div>
                                            <div className="col-lg-3 col-md-3 text-end">
                                                0.00
                                            </div>
                                        </div>

                                        <div className="row mx-auto mb-2 mt-5 text-dark fw-bold">
                                            <div className="col-lg-4 col-md-4">
                                                Total ( ₹ )
                                            </div>
                                            <div className="col-lg-8  col-md-8 text-end">
                                                0.00
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='text-end my-4'>
                                    <button
                                        className="ms-2 btn-cancel">
                                        Cancel
                                    </button>
                                    <button
                                        className="ms-2 btn-Outline">
                                        save as draft
                                    </button>
                                    <button
                                        className="ms-2 btn-primary">
                                        save and send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewEstimates
