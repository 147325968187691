import React from 'react'
import { Link } from "react-router-dom";

const CreateAccount = () => {
    return (
        <>
            <main className="Form-banner">
                <div className="container">
                    <div className="row mx-auto d-flex justify-content-center align-items-center Login-page">
                        <div className="col-lg-3 col-md-3 col-12 p-0">

                            <div className="Login-img1 position-relative">
                                <img src="/images/Loginimg-1.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 p-0 login-card p-5">
                            <div className='px-4'>
                                <div className="login-heading text-center">
                                    <h1>Welcome Back</h1>
                                    <p>Welcome back! Please enter your details</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-1 mt-1">
                                            <label className='mb-1'>Email Address</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="email"
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-lg-12 mb-1 mt-1 position-relative">
                                        <label className='mb-1'>Password</label>
                                        <input
                                            className="form-control position-relative"
                                            control="input"
                                            type="Password"
                                            name="password"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-lg-6">
                                        <input className="form-check-input me-2" type="checkbox" id="checkbox" />
                                        <label className="form-check-label" type="checkbox">
                                            Remember me
                                        </label>
                                    </div>
                                    <div className="col-lg-6 text-end">
                                        <label><Link to="/ForgotPassword">Forgot Password?</Link></label>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-lg-12 mt-4 mb-2">
                                        <div className="my-2 justify-content-center ">
                                            <Link to="/">
                                                <button
                                                    type="submit"
                                                    className=" w-100 btn-primary"
                                                >
                                                    Login
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-lg-6 social-img">
                                        <img src="/images/googleimg.png" alt="google" />
                                    </div>
                                    <div className="col-lg-6  social-img">
                                        <img src="/images/facebookimg.png" alt="facebook" />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-12 mt-3 text-center position-relative">
                                        <Link
                                            to="/Signin"
                                            className="mt-0 mb-3 p-tag"
                                            style={{ fontSize: "14px", color: "#707070!important" }}
                                        >
                                            <label>Don’t have an account? <a>Sign up</a></label>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 p-0 ">
                            <div className="Login-img position-relative">
                                <img src="/images/Loginimg-2.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default CreateAccount
