import React from 'react'
import { Link } from "react-router-dom";
const ForgotPassword = () => {
    return (
        <>
            <main className="Form-banner">
                <div className="container">
                    <div className="row mx-auto d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-3 col-12 p-0">
                            <div className="Login-img1 position-relative">
                                <img src="/images/Loginimg-1.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 p-0 login-card forgot-banner p-5">
                            <div className='px-4'>
                                <div className="forgot-img d-flex justify-content-center align-items-center">
                                    <img src="/images/forgotpass-img.svg" alt="img" />
                                </div>
                                <div className="login-heading text-center my-4">
                                    <h1>Forgot Password</h1>
                                    <p>Please enter your email and Select SEND EMIAL</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="my-3">
                                            <label className='mb-1'>Email Address</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="email"
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-12 text-end">
                                        <Link to="/Login"><button
                                            className="btn-Outline">
                                            Cancel
                                        </button>
                                        </Link>
                                        <Link to="/EnterOtp"><button
                                            className=" ms-3 btn-primary">
                                           Send Email
                                        </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 p-0 ">
                            <div className="Login-img position-relative">
                                <img src="/images/Loginimg-2.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ForgotPassword