import React from 'react'
import { Dropdown, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'


const Navbar = () => {
    return (
        <>
            <div className="Nav-container px-0">
                <div className="das-navbar">
                    <div className="container-fluid d-flex h-100 justify-content-between align-items-center">
                        <div className="user-deatils d-flex justify-content-start align-items-center me-3">
                            <div className='d-flex ms-4'>
                                <img src="/images/charm_menu-hamburger.png" alt="bar" />
                                <h1 className='h5 mb-0 ms-4 text-white'>LOGO HERE</h1>
                            </div>
                            <div class="row searchbox">
                                <div class="input-group search-box">
                                    <span class="input-group-text" id="basic-addon1"><i className='fas fa-search search-icon'></i></span>
                                    <input type="text" class="form-control" placeholder="Search.." aria-label="Search" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                        <div className="user-deatils d-flex justify-content-end align-items-center me-3">
                            <div className="me-4 nav-dropdown" id="notification-dropdown">
                                <Dropdown className="d-inline mx-4 position-relative">
                                    <Dropdown.Toggle id="dropdown-autoclose-true" className='w-100 d-flex justify-content-center align-items-center p-0'>
                                        <img src="/images/notificationicon.png" alt="notification" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="row dropdown-header d-flex justify-content-between align-items-center w-100 px-0 mx-0">
                                            <div className="col-lg-6">
                                                <h1 className='h6 mb-0'>Notifications</h1>
                                            </div>
                                            <div className="col-lg-6 text-end">
                                                <p className='mb-0'>CLEAR ALL</p>
                                            </div>
                                        </div>
                                        <NavDropdown.Divider />
                                        <li className='dropdown-item'>
                                            <div className="d-flex d-flex justify-content-between align-items-center">
                                                <div className="items d-flex justify-content-around align-items-center">
                                                    <div className="img">
                                                        <img src="/images/notification-drodown-img.svg" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h1>Brain Jo.<span>paid the invoice <br />#DF6532</span></h1>
                                                    </div>
                                                </div>
                                                <div className="d-flex text-end">
                                                    <p className='nav-drop-ptag'>4Mins ago</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='dropdown-item'>
                                            <div className="d-flex d-flex justify-content-between align-items-center">
                                                <div className="items d-flex justify-content-around align-items-center">
                                                    <div className="img">
                                                        <img src="/images/notification-drodown-img.svg" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h1>Brain Jo.<span>paid the invoice <br />#DF6532</span></h1>
                                                    </div>
                                                </div>
                                                <div className="d-flex text-end">
                                                    <p className='nav-drop-ptag'>4Mins ago</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='dropdown-item'>
                                            <div className="d-flex d-flex justify-content-between align-items-center">
                                                <div className="items d-flex justify-content-around align-items-center">
                                                    <div className="img">
                                                        <img src="/images/notification-drodown-img.svg" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h1>Brain Jo.<span>paid the invoice <br />#DF6532</span></h1>
                                                    </div>
                                                </div>
                                                <div className="d-flex text-end">
                                                    <p className='nav-drop-ptag'>4Mins ago</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='dropdown-item'>
                                            <div className="d-flex d-flex justify-content-between align-items-center">
                                                <div className="items d-flex justify-content-around align-items-center">
                                                    <div className="img">
                                                        <img src="/images/notification-drodown-img.svg" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h1>Brain Jo.<span>paid the invoice <br />#DF6532</span></h1>
                                                    </div>
                                                </div>
                                                <div className="d-flex text-end">
                                                    <p className='nav-drop-ptag'>4Mins ago</p>
                                                </div>
                                            </div>
                                        </li>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className='text-center mb-0'>View all Notifications</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className='nav-dropdown'>
                                <Dropdown className="d-inline mx-4 position-relative">
                                    <Dropdown.Toggle id="dropdown-autoclose-true" className='user-images w-100 d-flex justify-content-center align-items-center p-0'>
                                        <div className="user-images">
                                            <img src="/images/profileimg.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className='ms-2'>
                                            <div className="user-title">Mini kemon</div>
                                            <div className="user-type">Admin</div>
                                        </div>
                                        <div className='m-2 pb-1'>
                                            <img src="/images/chverondownicon.png" alt="Actions" />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <li className='dropdown-item'>
                                            <Link to="/Profile" ><i className="fas fa-user me-2" ></i>
                                                Profile</Link>
                                        </li>
                                        <li className='dropdown-item'>
                                            <a to="#" ><i class="fas fa-gear  me-2"></i>
                                                Setting</a>
                                        </li>
                                        <li className='dropdown-item'>
                                            <a href="/Login"><i className="fas fa-sign-out me-2" ></i>
                                                Logout</a>
                                        </li>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Navbar