import React from 'react'
import { Card, Space } from 'antd';
import { Link } from 'react-router-dom';

const Profile = () => {
    return (
        <div className="main-container mb-5">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card table-card border-0">
                        <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                            <div className='d-flex align-items-center'>
                                <h1 className='heading'>Dashboard</h1>
                                <div className="comHeading px-3">
                                    <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                        <li className="mx-1" >
                                            <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                        </li>
                                        <li className="mx-1">
                                            <Link to="#">Profile</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 position-relative">
                    <div className="cover-img">
                        <img src="/images/coverimg.svg" alt="cover-img" />
                    </div>
                    <div className="profile-img">
                        <img src="/images/profile-img.svg" alt="cover-img" />
                    </div>
                </div>
            </div>
            <div className="row mt-5 py-2">
                <div className="col-lg-12 text-center">
                    <h1 className='h5 mb-0'>Charles Hafner</h1>
                    <p className='fs-14'>UI/UX Designer</p>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-lg-4 mb-5">
                <Space direction="vertical" size={16}>
                    <Card
                        title="Personal Information"
                        extra={<Link to="/Editprofile">Edit</Link>}
                        style={{
                            width: 340,
                        }}
                    >
                        <div className="profile-cards">
                        <h1>About</h1>
                        <p>Charles Hafner</p>
                        <h1>Contact NO.</h1>
                        <p>+91 2356897585</p>
                        <h1>Email</h1>
                        <p>abc@gmail.com</p>
                        <h1>Address</h1>
                        <p>Charles Hafner</p>
                    </div>
                    </Card>
                </Space>
                </div>
                <div className="col-lg-4 mb-5">
                <Space direction="vertical" size={16}>
                    <Card
                        title="Professional Information"
                        extra={<Link to="/Editprofile">Edit</Link>}
                        style={{
                            width: 340,
                        }}
                    >
                    <div className="profile-cards">
                        <h1>About</h1>
                        <p>Charles Hafner</p>
                        <h1>Contact NO.</h1>
                        <p>+91 2356897585</p>
                        <h1>Email</h1>
                        <p>abc@gmail.com</p>
                        <h1>Address</h1>
                        <p>Charles Hafner</p>
                    </div>
                    </Card>
                </Space>
                </div>
                <div className="col-lg-4 mb-5">
                <Space direction="vertical" size={16}>
                    <Card
                        title="Company Information"
                        extra={<Link to="/Editprofile">Edit</Link>}
                        style={{
                            width: 340,
                        }}
                    >
                        <div className="profile-cards">
                        <h1>About</h1>
                        <p>Charles Hafner</p>
                        <h1>Contact NO.</h1>
                        <p>+91 2356897585</p>
                        <h1>Email</h1>
                        <p>abc@gmail.com</p>
                        <h1>Address</h1>
                        <p>Charles Hafner</p>
                    </div>
                    </Card>
                </Space>
                </div>
            </div>
        </div>
    )
}

export default Profile