import React from 'react';
import { Column } from '@ant-design/plots';

const Linchart = () => {
    const data = [
        {
            year: "Jan",
            //   value: 3,
            type: "New",

            sales: 400,
        },
        {
            year: 'Feb',
            sales: 600,
            type: "New",
        },
        {
            year: 'Mar',
            sales: 400,
            type: "Old",
        },
        {
            year: 'Apr',
            sales: 700,
            type: "New",
        },
        {
            year: 'May',
            sales: 600,
            type: "New",
        },
        {
            year: 'Jun',
            sales: 570,
            type: "New",
        },
        {
            year: 'Jul',
            sales: 360,
            type: "Old",
        },
        {
            year: 'Aug',
            sales: 800,
            type: "New",
        },
        {
            year: 'Sept',
            sales: 400,
            type: "New",
        },
        {
            year: 'Oct',
            sales: 1000,
            type: "New",
        },
        {
            year: 'Nov',
            sales: 650,
            type: "New",
        },
        {
            year: 'Dec',
            sales: 910,
            type: "New",
        },
    ];
    const config = {
        data,
        isStack: true,
        xField: 'year',
        yField: 'sales',
        seriesField: 'type',
        colorField: 'type',
        color: ({ type }) => {
            if (type === 'New') {
                return '#7B61FF';
            }
            else if (type === 'Old') {
                return '#FFCA40';
            }
            else {
                return '#FFCA40';
            }
        },
        minColumnWidth: 7,
        maxColumnWidth: 7,
        legend: {
            position: "top-right",
            layout: 'horizontal',
        },
        label: {
            style: {
                fill: '#FFFFFF',
                opacity: 0,
            },
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
                
            ],
        },
    };
    return (
        <Column {...config} style={{ width: "auto", height: "270px", marginTop: "20px" }} />
    )
}


export default Linchart