import React from 'react'
import { Link } from "react-router-dom";

const Passwordreset = () => {
    return (
        <>
            <main className="Form-banner">
                <div className="container">
                    <div className="row mx-auto d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-3 col-12 p-0">
                            <div className="Login-img1 position-relative">
                                <img src="/images/Loginimg-1.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 p-0 login-card newpass-banner p-5">
                            <div className='p-5 mt-4'>

                                <div className="reset-img d-flex justify-content-center">
                                    <img src="/images/resetdone.png" alt="resetdone" />
                                </div>
                                <div className="login-heading text-center my-4">
                                    <h1>Password reset</h1>
                                    <p className='px-4'>Your password has been successfully reset.</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 my-1">
                                        <div className="my-2 d-flex justify-content-center ">
                                            <Link to="/Login">
                                                <button
                                                    type="submit"
                                                    className="btn-primary"
                                                >
                                                    Continue login
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 p-0 ">
                            <div className="Login-img position-relative">
                                <img src="/images/Loginimg-2.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Passwordreset
