import React from 'react'
import { Link } from 'react-router-dom'

const EnterOtp = () => {
    return (
        <>
            <main className="Form-banner">
                <div className="container">
                    <div className="row mx-auto d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-3 col-12 p-0">

                            <div className="Login-img1 position-relative">
                                <img src="/images/Loginimg-1.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 p-0 login-card forgot-banner p-5">
                            <div className='px-4'>
                                <div className="otp-img d-flex justify-content-center align-items-center">
                                    <img src="/images/otp-img.svg" alt="img" />
                                </div>
                                <div className="login-heading text-center my-4">
                                    <h1>Please Enter OTP</h1>
                                    <p>Please Enter your OTP, you received on your mail </p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <div className="my-3">
                                            <img src="/images/otp-boxes.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-end">
                                        <Link to="/Forgotpassword"><button
                                            className="btn-Outline">
                                            Resend OTP
                                        </button></Link>
                                        <Link to="/Reenterpassword"><button
                                            className=" ms-3 btn-primary">
                                            Verify OTP
                                        </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-center">
                                        <Link
                                            to="/Signin"
                                            className="mt-0 mb-3"
                                        >
                                            <label>Login with Another account</label>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 p-0 ">
                            <div className="Login-img position-relative">
                                <img src="/images/Loginimg-2.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default EnterOtp
