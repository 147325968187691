import React from 'react'
import { Link } from "react-router-dom";

const Reenterpassword = () => {
    return (
        <>
            <main className="Form-banner">
                <div className="container">
                    <div className="row mx-auto d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-3 col-12 p-0">

                            <div className="Login-img1 position-relative">
                                <img src="/images/Loginimg-1.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 p-0 login-card newpass-banner p-5">
                            <div className='p-4'>
                                <div className="login-heading text-center my-4">
                                    <h1>Set new Password</h1>
                                    <p className='px-4'>Your new password must be different to old password</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-1 mt-1">
                                            <label>New password</label>
                                            <input
                                                className="form-control position-relative"
                                                control="input"
                                                type="Password"
                                                name="New password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my-2">
                                            <label>Confirm New password</label>
                                            <input
                                                className="form-control position-relative"
                                                control="input"
                                                type="Password"
                                                name="Confirm New password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-end">
                                        <Link to="/Login"><button
                                            className="btn-Outline">
                                            Back to Login
                                        </button>
                                        </Link>
                                        <Link to="/Passwordreset"><button
                                            className=" ms-3 btn-primary">
                                            Update
                                        </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 p-0 ">
                            <div className="Login-img position-relative">
                                <img src="/images/Loginimg-2.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Reenterpassword