import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Collapse, Dropdown, Menu, message, Space } from 'antd';
const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
};
const onChange = (key) => {
    console.log(key);
};
const { Panel } = Collapse;
const Sidebar = () => {
    const items = [
        {
            label: <Link to="/Category"><i class="fa-regular fa-circle pro-icon"></i>Category</Link>,
            key: '1',
        },
        {
            label: <Link to="/SubCategory"><i class="fa-regular fa-circle pro-icon"></i>Sub Category</Link>,
            key: '2',
        },
    ];

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <>
            <div className="sidebar">
                <div className="sidebar-content">
                    <ul className="list-unstyled">
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['Dashboard']}
                            items={[
                                {
                                    label: <Link to="/" className={splitLocation[1] === "" ? "active" : ""} >
                                        <i class="fas fa-house"></i>
                                        Dashboard
                                    </Link>, key: "Dashboard"
                                },
                                {
                                    label: <Link to="/Products" className={splitLocation[1] === "Products" ? "active" : ""}>
                                        <i class="fas fa-basket-shopping"></i>
                                        products
                                    </Link>, key: "Products"
                                },
                                {
                                    label: <Link to="#">
                                        <i class="fas fa-cart-shopping"></i>
                                        Sales
                                    </Link>, key: "task",
                                    children: [
                                        {
                                            label: <Link to="/Customers" className={splitLocation[1] === "Customers" ? "active" : ""}>
                                                Customer
                                            </Link>, key: "Customer",
                                        },
                                        {
                                            label: <Link to="/Estimates" className={splitLocation[1] === "Estimates" ? "active" : ""}>
                                                Estimates
                                            </Link>, key: "Estimates",
                                        }, {
                                            label: <Link to="/Invoice" className={splitLocation[1] === "Invoice" ? "active" : ""}>
                                                Invoices
                                            </Link>, key: "Invoice",
                                        },
                                    ]
                                }
                            ]}
                        />
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar