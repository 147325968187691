import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Table, Radio, Tag } from 'antd';


const Customers = () => {

    const [selectionType, setSelectionType] = useState('checkbox');

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
        },
        {
            title: 'Customer ID',
            dataIndex: 'CustomerID',
        },
        {
            title: 'Type',
            dataIndex: 'Type',
        },
        {
            title: 'Country',
            dataIndex: 'Country',
        },
        {
            title: 'Area',
            dataIndex: 'Area',
        },
        {
            title: 'Number',
            dataIndex: 'Number',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
        },
        {
            title: 'Status',
            key: 'Status',
            dataIndex: 'Status',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'gray' : '';
                            if (tag === 'Status 1') {
                            color = 'cyan';
                        }
                        
                        return (
                            <Tag color={color} key={tag} className="statustag">
                                {tag}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            render: (_, Product) => (
                <div className='d-flex justify-content-flex-start align-items-center'>
                    <div className='action-icon'><i className='fas fa-pencil action-icon'></i></div>
                    <p className='mb-0'><i className='fas fa-trash mx-2 action-icon-delete'></i></p>
                </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            Name: 'Ninja Tec...',
            CustomerID: 'C_012452D',
            Type: 'Type 1',
            Country: 'India',
            Area: 'Ahmedabad',
            Number: '+91 2535265242',
            Email: 'demo12@gmail.com',
            tags: ['Status 1'],
        },
        {
            key: '2',
            Name: 'Ninja Tec...',
            CustomerID: 'C_012452D',
            Type: 'Type 2',
            Country: 'India',
            Area: 'Ahmedabad',
            Number: '+91 2535265242',
            Email: 'demo12@gmail.com',
            tags: ['Status 1'],
        },
        {
            key: '3',
            Name: 'Ninja Tec...',
            CustomerID: 'C_012452D',
            Type: 'Type 3',
            Country: 'India',
            Area: 'Ahmedabad',
            Number: '+91 2535265242',
            Email: 'demo12@gmail.com',
            tags: ['Status 1'],

        },
        {
            key: '4',
            Name: 'Ninja Tec...',
            CustomerID: 'C_012452D',
            Type: 'Type 1',
            Country: 'India',
            Area: 'Ahmedabad',
            Number: '+91 2535265242',
            Email: 'demo12@gmail.com',
            tags: ['Status 1'],
        },


    ];
    return (
        <div className="main-container ">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card table-card border-0">
                        <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                            <div className='d-flex align-items-center'>
                                <h1 className='heading'>Customer</h1>
                                <div className="comHeading px-3">
                                    <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                        <li className="mx-1" >
                                            <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                        </li>
                                        <li className="mx-1">
                                            <Link to="#">Sales<i className="fas fa-chevron-right ms-2"></i></Link>
                                        </li>
                                        <li className="mx-1">
                                            <Link to="#">Customers</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <Link to="/NewCustomer">
                                <button
                                    className="ms-2 btn-primary">
                                    + Customers
                                </button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-lg-12 mb-5">
                    <div className="card table-card border-0">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="head">
                                <h1 className='h5 mb-0'>All Customers</h1>
                            </div>
                            <div className="searchbox d-flex justify-content-end align-items-center">
                                <div className="input-group search-box me-2">
                                    <span className="input-group-text" id="basic-addon1"><i className='fas fa-search search-icon'></i></span>
                                    <input type="text" className="form-control" placeholder="Search.." aria-label="Search" aria-describedby="basic-addon1" />
                                </div>
                                <i className="fas fa-ellipsis-vertical m-3"></i>
                            </div>
                        </div>
                        <div className="card-body">
                            <div>
                                <Radio.Group className='d-none'
                                    onChange={({ target: { value } }) => {
                                        setSelectionType(value);
                                    }}
                                    value={selectionType}
                                >
                                </Radio.Group>

                                <Table
                                    rowSelection={{
                                        type: selectionType,
                                        ...rowSelection,
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    pagination={{
                                        defaultPageSize: 8,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Customers