import React, { useState } from 'react'
import { Table, Radio, Tag, Tabs, DatePicker, Select } from 'antd';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';


const Invoice = () => {
  const isDisabled = true;

  const [selectionType, setSelectionType] = useState('checkbox');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const columns = [
    {
      title: 'Invoice  ID',
      dataIndex: 'InvoiceID',
      key: 'InvoiceID',
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
    },
    {
      title: 'End Date',
      key: 'EndDate',
      dataIndex: 'EndDate',
    },
    {
      title: 'Amount',
      key: 'Amount',
      dataIndex: 'Amount',
    },
    {
      title: 'Status',
      key: 'Status',
      dataIndex: 'Status',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? 'gray' : '';
            if (tag === 'Unpaid') {
              color = 'red';
            }
            else if (tag === 'Paid') {
              color = 'cyan';
            }
            return (
              <Tag color={color} key={tag} className="statustag">
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      render: (_, record) => (
        <>
          {record.tags.includes('Paid') && (
            <div disabled={isDisabled} className={isDisabled ? "disabled d-flex justify-content-flex-start align-items-center" : ""}>
              <div className='action-icon'>
                <i className='fas fa-eye action-icon' onClick={handleShow}></i></div>
              <p className='mb-0'><i className='fa-regular fa-credit-card mx-2 '><span className='ms-2'>Pay</span></i></p>
            </div>
          )}
          {record.tags.includes('Unpaid') && (
            <div className='d-flex justify-content-flex-start align-items-center'>
              <div className='action-icon'><i className='fas fa-eye' onClick={handleShow}></i></div>
              <p className='mb-0'><i className='fa-regular fa-credit-card mx-2 '><span className='ms-2'>Pay</span></i></p>
            </div>
          )}
        </>
      ),
    },
  ];
  const data = [
    {
      key: '1',
      InvoiceID: '#670604',
      StartDate: '01-01-2023',
      EndDate: '02-01-2023',
      Amount: '₹2301212',
      tags: ['Paid'],
    },

    {
      key: '2',
      InvoiceID: '#670604',
      StartDate: '01-01-2023',
      EndDate: '02-01-2023',
      Amount: '₹2301212',
      tags: ['Paid'],
    },
    {
      key: '3',
      InvoiceID: '#670604',
      StartDate: '01-01-2023',
      EndDate: '02-01-2023',
      Amount: '₹2301212',
      tags: ['Paid'],
    },
    {
      key: '4',
      InvoiceID: '#670604',
      StartDate: '01-01-2023',
      EndDate: '02-01-2023',
      Amount: '₹2301212',
      tags: ['Unpaid'],
    },

    {
      key: '5',
      InvoiceID: '#670604',
      StartDate: '01-01-2023',
      EndDate: '02-01-2023',
      Amount: '₹2301212',
      tags: ['Unpaid'],
    },
    {
      key: '6',
      InvoiceID: '#670604',
      StartDate: '01-01-2023',
      EndDate: '02-01-2023',
      Amount: '₹2301212',
      tags: ['Unpaid'],
    },
  ];
  const columns1 = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Items & Description',
      dataIndex: 'Itemsdescription',
      key: 'Itemsdescription',
    },
    {
      title: 'Qty',
      dataIndex: 'Qty',
      key: 'Qty',
    },
    {
      title: 'Rate',
      dataIndex: 'Rate',
      key: 'Rate',
    },
    {
      title: 'Discount',
      dataIndex: 'Discount',
      key: 'Discount',
    },
    {
      title: 'Tax%',
      dataIndex: 'Taxes',
      key: 'Taxes',
    },
    {
      title: 'Tax',
      dataIndex: 'Tax',
      key: 'Tax',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
    },
  ];
  const data1 = [
    {
      key: '1',
      number: '1',
      Itemsdescription: 'Broucher Design',
      Qty: '1.00',
      Rate: '300.00',
      Discount: '0.00',
      Taxes: '7.00%',
      Tax: '21.00',
      Amount: '300.00',
    },
    {
      key: '2',
      number: '2',
      Itemsdescription: 'Broucher Design',
      Qty: '1.00',
      Rate: '300.00',
      Discount: '0.00',
      Taxes: '7.00%',
      Tax: '21.00',
      Amount: '300.00',
    },
    {
      key: '3',
      number: '3',
      Itemsdescription: 'Broucher Design',
      Qty: '1.00',
      Rate: '300.00',
      Discount: '0.00',
      Taxes: '7.00%',
      Tax: '21.00',
      Amount: '300.00',
    },
  ];
  return (
    <>
      <div className="main-container ">
        <div className="row">
          <div className="col-lg-12">
            <div className="card table-card border-0">
              <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                <div className='d-flex align-items-center'>
                  <h1 className='heading'>Invoices</h1>
                  <div className="comHeading px-3">
                    <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                      <li className="mx-1" >
                        <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                      </li>
                      <li className="mx-1">
                        <Link to="#">Sales<i className="fas fa-chevron-right ms-2"></i></Link>
                      </li>
                      <li className="mx-1">
                        <Link to="#">Invoices</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <Link to="/NewInvoice">
                  <button
                    className="ms-2 btn-primary">
                    + Invoices
                  </button>
                </Link>

              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-12 mb-5">
            <div className="card table-card border-0">
              <div className="card-header d-flex justify-content-between align-items-center invoice-header px-0">

                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="All Invoices" key="1">

                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Draft" key="2" >

                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Past Invoices" key="3"></Tabs.TabPane>
                </Tabs>
                <div className='d-flex row justify-content-center align-items-center '>
                  <div className="col-lg-11  pe-1 d-flex justify-content-center align-items-center invoice-head">
                    <DatePicker placeholder="12.01.2017" onChange={onChange} className="w-25" />
                    <span className='px-2 span-tag'>to</span>
                    <DatePicker placeholder="12.02.2017" onChange={onChange} className="w-25" />
                    <Select
                      className='mx-2'
                      defaultValue="All"
                      onChange={handleChange}
                      options={[
                        {
                          value: 'Paid',
                          label: 'Paid',
                        },
                        {
                          value: 'Unpaid',
                          label: 'Unpaid',
                        },
                        {
                          value: 'overdue',
                          label: 'Overdue',
                        },
                        {
                          value: 'Partially Paid',
                          label: 'Partially Paid',
                        },
                      ]}
                    />
                    <button
                      className="btn-primary py-2 px-3 fs-12" style={{ boxShadow: "0px 4px 8px rgba(0, 122, 255, 0.25)" }}>
                      Export File
                    </button>
                  </div>

                </div>
                <div className="searchbox d-flex justify-content-end align-items-center ">
                  <div class="input-group search-box me-2">
                    <span class="input-group-text" id="basic-addon1"><i className='fas fa-search search-icon'></i></span>
                    <input type="text" class="form-control" placeholder="Search.." aria-label="Search" aria-describedby="basic-addon1" />
                  </div>
                  <i className="fas fa-ellipsis-vertical m-3"></i>
                </div>
              </div>
              <div className="card-body">
                <div>
                  <Radio.Group className='d-none'
                    onChange={({ target: { value } }) => {
                      setSelectionType(value);
                    }}
                    value={selectionType}
                  >
                  </Radio.Group>

                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                      defaultPageSize: 8,
                    }}
                  />
                  <div>
                    <div className="">
                      <div className="row">
                        <div className="col-lg-12"></div>
                        <Offcanvas show={show} placement="end" onHide={handleClose} style={{ width: "736px" }}>
                          <Offcanvas.Header closeButton className='py-0'>
                            <div className="option-line d-flex">
                              <div className="d-flex p-2 options ">
                                <i className='fas fa-edit pe-2'></i>
                                <p className='mb-0 pb-0'>edit</p>
                              </div>
                              <div className="d-flex p-2 options">
                                <i className='fas fa-print pe-2'></i>
                                <p className='me-2 mb-0 pb-0'>PDF/Print</p>
                              </div>
                              <div className="d-flex p-2 options">
                                <i className='fas fa-share pe-2'></i>
                                <p className='mb-0 pb-0'>Share</p>
                              </div>
                            </div>
                          </Offcanvas.Header>
                          <Offcanvas.Body className="invoice_body">
                            <div className="row d-flex justify-content-end">
                              <div className="card col-lg-12 border-0">

                                <div className="card p-4 border-0">
                                  <div className="card inner-card rounded-0">
                                    <div className="row d-flex justify-content-between align-items-center invoice-border ">
                                      <div className="col-lg-6 invoice-info">
                                        <h1 className='h6 mb-1'>Itaims Ui</h1>
                                        <p className='mb-0'>Gujrat</p>
                                        <p className='mb-0 mb-2'>India</p>
                                      </div>
                                      <div className="col-lg-6 text-end">
                                        <h1 className='h2'>TAX INVOICE</h1>
                                      </div>
                                    </div>
                                    <div className="row invoice-border">
                                      <div className="col-lg-6 border-right">
                                        <div className="row d-flex justify-content-between align-items-center">
                                          <div className="col-lg-5 invoice-info my-2">
                                            <p>#</p>
                                            <p>Invoice Date</p>
                                            <p>Terms</p>
                                            <p>Due Date</p>
                                            <p>P.O.#</p>
                                          </div>
                                          <div className="col-lg-5 my-2">
                                            <h1 className='info-head'>:INV-17</h1>
                                            <h1 className='info-head'>:22/12/2022</h1>
                                            <h1 className='info-head'>:Due on Receipt</h1>
                                            <h1 className='info-head'>:22/12/2022</h1>
                                            <h1 className='info-head'>:So-17</h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                      </div>
                                    </div>
                                    <div className="row invoice-border bg-light">
                                      <div className="col-lg-6 border-right"><label>Bill To</label></div>
                                      <div className="col-lg-6"><label>Ship To</label></div>
                                    </div>
                                    <div className="row invoice-info invoice-border">
                                      <div className="col-lg-6 border-right py-2">
                                        <h1 className='info-head'>Rob and Joe Traders</h1>
                                        <p>34,Riche street</p>
                                        <p>Chennai</p>
                                        <p>363001,Tamil nadu</p>
                                        <p>India</p>
                                      </div>
                                      <div className="col-lg-6 py-2">
                                        <p>34,Riche street</p>
                                        <p>Chennai</p>
                                        <p>363001,Tamil nadu</p>
                                        <p>India</p>
                                      </div>
                                    </div>
                                    <div className="row invoice-border py-2">
                                      <div className="col-lg-12 info-head fs-14">Subject:</div>
                                      <div className="col-lg-12 info-head fs-14">Description:</div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 px-0">
                                        <Table
                                          columns={columns1}
                                          dataSource={data1}
                                          pagination={false}
                                          scroll={{ x: true }}
                                          bordered
                                        />
                                      </div>
                                    </div>
                                    <div className="row d-flex">
                                      <div className="col-lg-6 border-right">
                                        <div className="row my-3">
                                          <div className="col-lg-12">
                                            <p className='mb-0 fs-12'>Total In Words</p>
                                            <h1 className='fs-12 mt-1'><em>Indian Rupee Six hundred sixty-two and Seventy-Five Paise Only</em></h1>
                                          </div>
                                        </div>
                                        <div className="row my-3">
                                          <div className="col-lg-12">
                                            <p className='fs-12'>Thanks for your Business</p>
                                          </div>
                                        </div>
                                        <div className="row my-3">
                                          <div className="col-lg-12">
                                            <p className='fs-12'>Payment Options:</p>
                                          </div>
                                        </div>
                                        <div className="row my-3">
                                          <div className="col-lg-12">
                                            <p className='fs-12 mb-0'>Terms & Conditions</p>
                                            <p className='fs-12'>Your Company's Terms & Condition will be displayed here. you can add it in the invoice preferences page under Settings.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 text-end">
                                        <div className="row mt-3">
                                          <div className="col-lg-6"><p className='fs-12'>SubTotal</p></div>
                                          <div className="col-lg-6"><p className='fs-12'>630.00</p></div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6"><p className='fs-12'>Discount</p></div>
                                          <div className="col-lg-6"><p className='fs-12'>0.00</p></div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6"><p className='fs-12'>Sample Tax1(4.70%)</p></div>
                                          <div className="col-lg-6"><p className='fs-12'>11.75</p></div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6"><p className='fs-12'>Sample Tax12(7.00%)</p></div>
                                          <div className="col-lg-6"><p className='fs-12'>21.00</p></div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6"><h1 className='fs-12'>Total</h1></div>
                                          <div className="col-lg-6"><h1 className='fs-12'>21.00</h1></div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6"><p className='fs-12'>payment made</p></div>
                                          <div className="col-lg-6"><p className='fs-12 text-danger'>(-21.00)</p></div>
                                        </div>
                                        <div className="row border-bottom">
                                          <div className="col-lg-6"><h1 className='fs-12'>Balance due</h1></div>
                                          <div className="col-lg-6"><h1 className='fs-12'>562.75</h1></div>
                                        </div>
                                        <div className="row text-center py-3">
                                          <div className="col-lg-12"><h1 className='h6 mt-5'>Authorized Signature</h1></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Invoice