import React from 'react'
import { Pie } from '@ant-design/plots';

const Donute = () => {
    const data = [
        {
            type: 'Received',
            value: 12.3,
        },
        {
            type: 'Pending',
            value: 24.3,
        },
        {
            type: 'Invoices',
            value: 48.8,
        },
    ];
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
            if (type === 'Invoices') {
                return '#7638FF';
            }
            else if (type === 'Pending') {
                return '#FF737B';
            }
            else if (type === 'Received') {
                return '#1EC1B0';
            }
            else {
                return '#FDA600';
            }
        },
        legend: {
            // offsetX: 100,
            // offsetY: 10,
            position: "bottom-left",
            layout: 'horizontal',
        },

        radius: 1,
        innerRadius: 0.54,
        label: "true",
        value: "true",

        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '16px',
                },
                content: '',

            },
        },
    };
    return (
        <Pie {...config} style={{ width: "auto", height: "270px", }} />
    )
}

export default Donute