import { DatePicker } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const Editprofile = () => {
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };
    return (
        <>
            <div className="main-container pb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card table-card border-0">
                            <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                                <div className='d-flex align-items-center'>
                                    <h1 className='heading'>Dashboard</h1>
                                    <div className="comHeading px-3">
                                        <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                            <li className="mx-1" >
                                                <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                            </li>
                                            <li className="mx-1">
                                                <Link to="/Profile">Profile</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body px-2 mb-3">
                    <div className="row card pt-3">
                        <div className="col-lg-12 table-card">
                            <div className="row mx-auto">
                                <div className="col-lg-5">
                                    <h1 className='h4'>Profile Photo</h1>
                                    <div className='user-pro-img'>
                                        <img src="/images/profile-img.svg" alt="cover-img" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <h1 className='h4'>Profile Photo</h1>
                                    <div className="cover-img">
                                        <img src="/images/cover-img2.svg" alt="cover-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body px-2 mb-5 edit-pro">
                    <div className="row card p-3">
                        <div className="col-lg-12 table-card">
                            <div className="head">
                                <h1 className='h5 mb-0'>Personal Information</h1>
                            </div>
                            <div className="row d-flex justify-content-between align-items-center my-2">
                                <div className="col-lg-3">
                                    <label>Name</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="text"
                                        name="Name"
                                        Placeholder="Name"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label>Designation</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="text"
                                        name="Designation"
                                        Placeholder="Designation"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label>Company Name</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="text"
                                        name="Company Name"
                                        Placeholder="Company Name"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label>Website</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="text"
                                        name="Website"
                                        Placeholder="Website"
                                    />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between align-items-center my-2">
                                <div className="col-lg-3">
                                    <label>Mobile</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="text"
                                        name="Mobile"
                                        Placeholder="Mobile"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label>Address</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="text"
                                        name="Address"
                                        Placeholder="Address"
                                    />
                                </div>
                                
                                <div className="col-lg-3">
                                    <label>Joining Date</label>
                                    <DatePicker placeholder="21/12/2022" onChange={onChange} className="w-100" />
                                </div>
                                <div className="col-lg-3">
                                    <label>Email</label>
                                    <input
                                        className="form-control"
                                        control="input"
                                        type="email"
                                        name="Email"
                                        Placeholder="Email"
                                    />
                                </div>
                            </div>
                            <div className="my-3">
                                <div className="head">
                                    <h1 className='h5 mb-0'>Professional Information</h1>
                                </div>
                                <div className="row d-flex justify-content-between align-items-center my-2">
                                    <div className="col-lg-3">
                                        <label>Name</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Name"
                                            Placeholder="Name"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Designation</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Designation"
                                            Placeholder="Designation"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Company Name</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Company Name"
                                            Placeholder="Company Name"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Website</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Website"
                                            Placeholder="Website"
                                        />
                                    </div>

                                </div>
                                <div className="row d-flex justify-content-between align-items-center my-2">
                                    <div className="col-lg-3">
                                        <label>Mobile</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Mobile"
                                            Placeholder="Mobile"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Address</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Address"
                                            Placeholder="Address"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Joining Date</label>
                                        <DatePicker placeholder="21/12/2022" onChange={onChange} className="w-100" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Email</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="email"
                                            name="Email"
                                            Placeholder="Email"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="head">
                                    <h1 className='h5 mb-0'>Company Information</h1>
                                </div>
                                <div className="row d-flex justify-content-between align-items-center my-2">
                                    <div className="col-lg-3">
                                        <label>Name</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Name"
                                            Placeholder="Name"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Designation</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Designation"
                                            Placeholder="Designation"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Company Name</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Company Name"
                                            Placeholder="Company Name"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Website</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Website"
                                            Placeholder="Website"
                                        />
                                    </div>

                                </div>
                                <div className="row d-flex justify-content-between align-items-center my-2">
                                    <div className="col-lg-3">
                                        <label>Mobile</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Mobile"
                                            Placeholder="Mobile"
                                        />

                                    </div>
                                    <div className="col-lg-3">
                                        <label>Address</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="text"
                                            name="Address"
                                            Placeholder="Address"
                                        />
                                    </div>
                                    
                                    <div className="col-lg-3">
                                        <label>Joining Date</label>
                                        <DatePicker placeholder="21/12/2022" onChange={onChange} className="w-100" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Email</label>
                                        <input
                                            className="form-control"
                                            control="input"
                                            type="email"
                                            name="Email"
                                            Placeholder="Email"
                                        />
                                    </div>

                                </div>
                                <div className='text-end mt-4'>
                                    <Link to="/profile"><button
                                        className="ms-2 btn-Outline">
                                        Discard
                                    </button>
                                    </Link>
                                    <Link to="/profile"><button
                                        className="ms-2 btn-primary">
                                        save Changes
                                    </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editprofile
