import React, { useState } from 'react'
import { Table, Radio, Tag, Space, Dropdown } from 'antd';
import { Link } from 'react-router-dom';


const Estimates = () => {
    const [selectionType, setSelectionType] = useState('checkbox');

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    };
    const items = [
        {
            label:
                <span><i className="fa-regular fa-edit me-2"></i>Edit</span>,
            key: '0',
        },
        {
            label: <span><i className="fa-solid fa-trash me-2"></i>Delete</span>,
            key: '1',
        },
        {
            label: <span><i className="fa-regular fa-eye me-2"></i>View</span>,
            key: '2',
        },
        {
            label: <span><i className="fa-regular fa-file me-2"></i>Convert to invoice</span>,
            key: '3',
        },
        {
            label: <span><i className="fa-regular fa-circle-check me-2"></i>Mark as sent</span>,
            key: '4',
        },
        {
            label: <span><i className="fa-regular fa-paper-plane me-2"></i>Send Estimate</span>,
            key: '5',
        },
        {
            label: <span><i className="fa-regular fa-circle-check me-2"></i>Mark as Accepted</span>,
            key: '6',
        },
        {
            label: <span><i class="fa-regular fa-circle-xmark me-2"></i>Mark as Rejected</span>,
            key: '7',
        },
    ];
    const columns = [

        {
            title: 'Estimate Number',
            dataIndex: 'EstimateNumber',
            key: 'EstimateNumber',
            // render: (text) => <a>{text}</a>,
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'Email',
            key: 'Email',
            dataIndex: 'Email',

        },
        {
            title: 'Customer Name',
            key: 'CustomerName',
            dataIndex: 'CustomerName',

        },
        {
            title: 'Status',
            key: 'Status',
            dataIndex: 'Status',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'gray' : '';
                        if (tag === 'Declined') {
                            color = 'red';
                        }
                        else if (tag === 'Sent') {
                            color = 'cyan';
                        }
                        else if (tag === 'Accepted') {
                            color = 'green';
                        }
                        else if (tag === 'Expired') {
                            color = 'orange';
                        }
                        return (
                            <Tag color={color} key={tag} className="statustag">
                                {tag}
                            </Tag>
                        );
                    })}
                </>
            ),
        },

        {
            title: 'Amount',
            key: 'Amount',
            dataIndex: 'Amount',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text) =>
                <Dropdown
                    menu={{
                        items,

                    }}
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <i className="fa-solid fa-ellipsis-vertical mx-3"></i>
                        </Space>
                    </a>
                </Dropdown>
        },
    ];

    const data = [
        {
            key: '1',
            Date: '23.07.2022',
            EstimateNumber: '625434',
            Email: 'demo12@gmail.com',
            CustomerName: 'Ninja Technolabs',
            tags: ['Sent'],
            Amount: '₹2301212',
        },
        {
            key: '2',
            Date: '23.07.2022',
            EstimateNumber: '625434',
            Email: 'demo12@gmail.com',
            CustomerName: 'Ninja Technolabs',
            Status: 'Draft',
            Amount: '₹2301212',
            tags: ['Accepted'],
        },
        {
            key: '3',
            Date: '23.07.2022',
            EstimateNumber: '625434',
            Email: 'demo12@gmail.com',
            CustomerName: 'Ninja Technolabs',
            tags: ['Declined'],
            Amount: '₹2301212',
        },
        {
            key: '4',
            Date: '23.07.2022',
            EstimateNumber: '625434',
            Email: 'demo12@gmail.com',
            CustomerName: 'Ninja Technolabs',
            tags: ['Expired'],
            Amount: '₹2301212',

        },
        {
            key: '5',
            Date: '23.07.2022',
            EstimateNumber: '625434',
            Email: 'demo12@gmail.com',
            CustomerName: 'Ninja Technolabs',
            tags: ['Expired'],
            Amount: '₹2301212',
        },

    ];

    return (
        <>
            <div className="main-container ">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card table-card border-0">
                            <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                                <div className='d-flex align-items-center'>
                                    <h1 className='heading'>Estimate</h1>
                                    <div className="comHeading px-3">
                                        <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                            <li className="mx-1" >
                                                <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                            </li>
                                            <li className="mx-1">
                                                <Link to="#">Sales<i className="fas fa-chevron-right ms-2"></i></Link>
                                            </li>
                                            <li className="mx-1">
                                                <Link to="#">Estimate</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <Link to="/NewEstimates">
                                    <button
                                        className="ms-2 btn-primary">
                                        + Estimate
                                    </button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-12 mb-5">
                        <div className="card table-card border-0">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <div className="head">
                                    <h1 className='h5 mb-0'>All Estimate</h1>
                                </div>
                                <div className="searchbox d-flex justify-content-end align-items-center ">
                                    <div class="input-group search-box me-2">
                                        <span class="input-group-text" id="basic-addon1"><i className='fas fa-search search-icon'></i></span>
                                        <input type="text" class="form-control" placeholder="Search.." aria-label="Search" aria-describedby="basic-addon1" />
                                    </div>
                                    <i className="fas fa-ellipsis-vertical m-3"></i>

                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <Radio.Group className='d-none'
                                        onChange={({ target: { value } }) => {
                                            setSelectionType(value);
                                        }}
                                        value={selectionType}
                                    >
                                    </Radio.Group>

                                    <Table
                                        rowSelection={{
                                            type: selectionType,
                                            ...rowSelection,
                                        }}
                                        columns={columns}
                                        dataSource={data}
                                        pagination={{
                                            defaultPageSize: 8,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Estimates
