import ProgressBar from 'react-bootstrap/ProgressBar';
import React from 'react'
import "./Dashboard.css"
import { Select } from 'antd';
import Linchart from './Linchart';
import Donute from './Donute';

const Dashboard = () => {
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };
  return (
    <div className="main-container">
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col-xl-3 col-lg-3 col-md-6 ">
            <div className="card dash-card">
              <div className="card-body">
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='dash-icon'>
                    <i className='fas fa-cog'></i>
                  </div>
                  <div>
                    <h1>Amount Due</h1>
                    <p>1,640</p>
                  </div>
                </div>
                <ProgressBar now={60} />
                <div>
                  <p className='mb-0 ranking'> <i className='fas fa-arrow-down'></i> <span>1.15%</span> since last week</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3  col-md-6">
            <div className="card dash-card">
              <div className="card-body">
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='dash-icon'>
                    <i className='fas fa-cog'></i>
                  </div>
                  <div>
                    <h1>Customers</h1>
                    <p>1,640</p>
                  </div>
                </div>
                <ProgressBar now={60} />
                <div>
                  <p className='mb-0 ranking'> <i className='fas fa-arrow-down'></i> <span>1.15%</span> since last week</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3  col-md-6">
            <div className="card dash-card">
              <div className="card-body">
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='dash-icon'>
                    <i className='fas fa-cog'></i>
                  </div>
                  <div>
                    <h1>Invoices</h1>
                    <p>1,640</p>
                  </div>
                </div>
                <ProgressBar now={60} />
                <div>
                  <p className='mb-0 ranking'> <i className='fas fa-arrow-down'></i> <span>1.15%</span> since last week</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3  col-md-6">
            <div className="card dash-card">
              <div className="card-body">
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='dash-icon'>
                    <i className='fas fa-cog'></i>
                  </div>
                  <div>
                    <h1>Estimates</h1>
                    <p>1,640</p>
                  </div>
                </div>
                <ProgressBar now={60} />
                <div>
                  <p className='mb-0 ranking'> <i className='fas fa-arrow-down'></i> <span>1.15%</span> since last week</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6  col-md-6 mb-4">
            <div className="card p-0 map-card">
              <div className="card-header px-3 py-3 d-flex justify-content-between align-items-center">
                <h2 className='mb-0'>Customer Analytics</h2>
                <Select
                  showSearch
                  defaultValue="Monthly"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'weekly',
                      label: 'Weekly',
                    },
                    {
                      value: 'monthly',
                      label: 'Monthly',
                    },
                    {
                      value: 'yearly',
                      label: 'Yearly',
                    },
                  ]}
                />
              </div>
              <div className="card-body px-4 py-3">
                <Linchart />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 mb-4">
            <div className="card p-0 map-card">
              <div className="card-header px-3 py-3 d-flex justify-content-between align-items-center">
                <h2 className='mb-0'>Estimates Analytics</h2>
                <Select
                  showSearch
                  defaultValue="Monthly"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'weekly',
                      label: 'Weekly',
                    },
                    {
                      value: 'monthly',
                      label: 'Monthly',
                    },
                    {
                      value: 'yearly',
                      label: 'Yearly',
                    },
                  ]}
                />
              </div>
              <div className="card-body px-4 py-3">
                <Donute />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6  col-md-6 mb-4">
            <div className="card p-0 map-card">
              <div className="card-header px-3 py-3 d-flex justify-content-between align-items-center">
                <h2 className='mb-0'>Recent Invoices</h2>

              </div>
              <div className="card-body px-4 py-3">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
