import React, { useState } from 'react';
import { Input, DatePicker } from 'antd';
import { Link } from 'react-router-dom';

const NewCustomer = () => {
    const { TextArea } = Input;
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    return (
        <div className="main-container pb-4">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card table-card border-0">
                        <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                            <div className='d-flex align-items-center'>
                                <h1 className='heading'>Customer</h1>
                                <div className="comHeading px-3">
                                    <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                        <li className="mx-1" >
                                            <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                        </li>
                                        <li className="mx-1">
                                            <Link to="#">Sales<i className="fas fa-chevron-right ms-2"></i></Link>
                                        </li>
                                        <li className="mx-1">
                                            <Link to="#">Customer</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body px-0">
                        <div className='row mx-auto'>
                            <div className="col-lg-12 card border-0 mb-5 px-0">
                                <div className="card-body px-2 cust-details">
                                    <div className="col-lg-12 table-card px-3 mt-2 pb-1">
                                        <div className="head">
                                            <h1 className='h5 mb-0'>Basic Information</h1>
                                        </div>
                                    </div>
                                    <div className="row px-3 d-flex justify-content-between align-items-center my-2">
                                        <div className="col-lg-3">
                                            <label>Customer Name</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="text"
                                                name="Customer Name"
                                                Placeholder="Name"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Company Name</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="text"
                                                name="Company Name"
                                                Placeholder="Company Name"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Contact Number</label>
                                            <DatePicker placeholder="Contact Number" onChange={onChange} className="w-100" />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Website</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="text"
                                                name="Website"
                                                Placeholder="Website"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 table-card px-3">
                                        <div className="head">
                                            <h1 className='h5 mb-0'>Billing Address</h1>
                                        </div>
                                    </div>
                                    <div className="row px-3">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>Name</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Name"
                                                        Placeholder="Name"
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Country</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Company Name"
                                                        Placeholder="Company Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col-lg-6">
                                                    <label>State</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="State"
                                                        Placeholder="State"
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>City</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="City"
                                                        Placeholder="City"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col-lg-6">
                                                    <label>Contact Number</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Contact Number"
                                                        Placeholder="Contact Number"
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Zip code</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Zip code"
                                                        Placeholder="Zip code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Address</label>
                                            <TextArea placeholder="Enter Address" onChange={onChange} style={{
                                                height: 204,
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 table-card px-3">
                                        <div className="head">
                                            <h1 className='h5 mb-0'>Shipping Address</h1>
                                        </div>
                                    </div>
                                    <div className="row px-3">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>Name</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Name"
                                                        Placeholder="Name"
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Country</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Company Name"
                                                        Placeholder="Company Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col-lg-6">
                                                    <label>State</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="State"
                                                        Placeholder="State"
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>City</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="City"
                                                        Placeholder="City"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col-lg-6">
                                                    <label>Contact Number</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Contact Number"
                                                        Placeholder="Contact Number"
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Zip code</label>
                                                    <input
                                                        className="form-control"
                                                        control="input"
                                                        type="text"
                                                        name="Zip code"
                                                        Placeholder="Zip code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Address</label>
                                            <TextArea placeholder="Enter Address" onChange={onChange} style={{
                                                height: 204,
                                            }} />
                                        </div>
                                    </div>
                                    <div className='text-end my-4'>
                                        <button
                                            className="ms-2 btn-cancel">
                                            Cancel
                                        </button>
                                        <button
                                            className="ms-2 btn-Outline">
                                            save as draft
                                        </button>
                                        <button
                                            className="ms-2 me-3 btn-primary">
                                            save and send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewCustomer