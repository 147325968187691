import { Input } from 'antd';
import React from 'react'
import { Link } from "react-router-dom";

const Signin = () => {
    return (
        <>
            <main className="Form-banner">
                <div className="row mx-auto d-flex justify-content-center align-items-center Createacc-banner">
                    <div className="col-lg-6 col-md-6 col-12 p-0 formpage vh-100">
                        <div className="login-content px-3  d-flex justify-content-center align-items-center">
                            <form>
                                <div className="row signin-content">
                                    <div className="col-lg-12">
                                        <div className="signin-heading my-3">
                                            <h1>Welcome To ITaims</h1>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 label-tag">
                                        <div className="mb-1 mt-1">
                                            <label>Company Name</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="text"
                                                name="Company Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 label-tag" >
                                        <div className="mb-1 mt-1">
                                            <label>Email address</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="email"
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 label-tag">
                                        <div className="mb-1 mt-1">
                                            <label>Mobile Number</label>
                                            <input
                                                className="form-control"
                                                control="input"
                                                type="number"
                                                name="number"

                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 label-tag position-relative">
                                        <div className="mb-1 mt-1">
                                            <div className="position-relative">
                                                <label>Password</label>
                                                <Input.Password />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 label-tag position-relative">
                                        <div className="mb-1 mt-1">
                                            <div className="position-relative">
                                                <label>Country</label>
                                                <select class="form-select" aria-label="Default select example">
                                                    <option value="1">India</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 label-tag position-relative">
                                        <div className="mb-1 mt-1">
                                            <div className="position-relative">
                                                <label>State</label>
                                                <select class="form-select" aria-label="Default select example">
                                                    <option value="1">Gujarat</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 label-tag position-relative">
                                        <div className="form-group form-check mt-1 mb-2">
                                            <input className="form-check-input" type="checkbox" id="checkbox" />
                                            <label className="form-check-label" type="checkbox">
                                                I agree to all the Terms and Privacy policy
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 mt-3">
                                        <div className="my-2 d-flex justify-content-center ">
                                            <Link to="/Signin">
                                                <button
                                                    type="submit"
                                                    className="white-btn"
                                                >
                                                    Create Account
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row my-2 d-flex justify-content-center align-items-center">
                                        <div className="col-lg-3 ms-2">
                                            <img src="/images/signingoogle-img.svg" alt="google" />
                                        </div>
                                        <div className="col-lg-3 ms-2">
                                            <img src="/images/signinfacebook-img.svg" alt="facebook" />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-12 text-center">
                                            <Link
                                                to="/Signin"
                                                className="mt-0 mb-3 label-tag">
                                                <label>You  have an account? <Link to="/Login">Log In</Link></label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 p-0 form-img position-relative vh-100 d-flex justify-content-center align-items-center flex-column">
                        <img src="/images/signin-img.svg" alt="signin-img" />
                    </div>
                </div>
            </main>
        </>
    )
}

export default Signin
