import React, { useState } from 'react'
import { Table, Radio } from 'antd';
import {  Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

const Products = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectionType, setSelectionType] = useState('checkbox');

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    };

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'ProductName',
        },
        {
            title: 'Product ID',
            dataIndex: 'ProductID',
        },
        {
            title: 'HSN Code',
            dataIndex: 'HSNCode',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            render: (_, Product) => (
                <div className='d-flex justify-content-flex-start align-items-center'>
                    <div className='action-icon'><i className='fas fa-pencil action-icon'></i></div>
                    <p className='mb-0'><i className='fas fa-trash mx-2 action-icon-delete'></i></p>
                </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            ProductName: 'Demo',
            ProductID: '#0125DF20',
            HSNCode: '6253',
            Status: '+91 2365895635',

        },
        {
            key: '2',
            ProductName: 'Demo',
            ProductID: '#0125DF20',
            HSNCode: '6253',
            Status: '+91 2365895635',
        },
        {
            key: '3',
            ProductName: 'Demo',
            ProductID: '#0125DF20',
            HSNCode: '6253',
            Status: '+91 2365895635',
        },
        {
            key: '4',
            ProductName: 'Demo',
            ProductID: '#0125DF20',
            HSNCode: '6253',
            Status: '+91 2365895635',
        },
        {
            key: '5',
            ProductName: 'Demo',
            ProductID: '#0125DF20',
            HSNCode: '6253',
            Status: '+91 2365895635',
        },
    ];

    return (
        <div className="main-container ">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card table-card border-0">
                        <div className="search-box d-flex justify-content-between align-items-center mt-4 ">
                            <div className='d-flex align-items-center'>
                                <h1 className='heading'>Products</h1>
                                <div className="comHeading px-3">
                                    <ul className="list-unstyled d-flex mb-2 breadcrumbs">
                                        <li className="mx-1" >
                                            <Link to="/">Home<i className="fas fa-chevron-right ms-2"></i> </Link>
                                        </li>
                                        <li className="mx-1">
                                            <Link to="#">All products</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <button
                                className="ms-2 btn-primary" onClick={handleShow}>
                                + Add Products
                            </button>

                            <Modal show={show} onHide={handleClose} centered className='py-4'>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-lg-12 login-content">
                                            <Modal.Header closeButton>
                                                <Modal.Title><h1 className="modal-title pt-4 pb-3">Add Products</h1></Modal.Title>
                                            </Modal.Header>
                                        </div>
                                    </div>
                                    <form>
                                        <div className="row mx-2 my-2 mt-3">
                                            <div className="col-lg-6">
                                                <div className="mb-2 mt-1">
                                                    <label htmlFor="Product Name">Product Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Product Name"
                                                        placeholder='Product Name'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-2 mt-1">
                                                    <label htmlFor="Product ID">Product ID</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Product ID"
                                                        placeholder='Designation'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mx-2 my-2'>
                                            <div className="col-lg-6">
                                                <div className="mb-2 mt-1">
                                                    <label htmlFor="HSN Code">HSN Code</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="HSN Code"
                                                        placeholder='Contact Number'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-2 mt-1">
                                                    <label htmlFor="Status">Status</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Status"
                                                        placeholder='Website'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-2 my-2">
                                            <div className="col-lg-12">
                                                <label>Description <span>(Optional)</span></label>
                                                <TextArea placeholder="Contact Number" style={{
                                                    height: 90,
                                                }} />
                                            </div>
                                        </div>
                                    </form>
                                    <div className='text-end my-4 mx-3 pb-3 modelbody'>
                                        <button
                                            className="ms-2 btn-cancel" onClick={handleClose}>
                                            Cancel
                                        </button>
                                        <button
                                            className="ms-2 btn-Outline">
                                            save as draft
                                        </button>
                                        <button
                                            className="ms-2 btn-primary">
                                            save and Add
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-lg-12 mb-5">
                    <div className="card table-card border-0">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="head">
                                <h1 className='h5 mb-0'>All Products</h1>
                            </div>
                            <div className="searchbox d-flex justify-content-end align-items-center ">
                                <div class="input-group search-box me-2">
                                    <span class="input-group-text" id="basic-addon1"><i className='fas fa-search search-icon'></i></span>
                                    <input type="text" class="form-control" placeholder="Search.." aria-label="Search" aria-describedby="basic-addon1" />
                                </div>
                                <i className="fas fa-ellipsis-vertical m-3"></i>
                            </div>
                        </div>
                        <div className="card-body">
                            <div>
                                <Radio.Group className='d-none'
                                    onChange={({ target: { value } }) => {
                                        setSelectionType(value);
                                    }}
                                    value={selectionType}
                                >
                                </Radio.Group>

                                <Table
                                    rowSelection={{
                                        type: selectionType,
                                        ...rowSelection,
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    pagination={{
                                        defaultPageSize: 8,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Products