import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import './App.css';
import Login from "./LoginComponents/Login";
import Signin from "./LoginComponents/Signin";
import EnterOtp from "./LoginComponents/EnterOtp";
import ForgotPassword from "./LoginComponents/ForgotPassword";
import Reenterpassword from "./LoginComponents/Reenterpassword";
import Sidebar from './DashboardComponents/Sidebar';
import Navbar from './DashboardComponents/Navbar';
import NewEstimates from './DashboardComponents/NewEstimates';
import Estimates from './DashboardComponents/Estimates';
import Invoice from './DashboardComponents/Invoice';
import NewCustomer from './DashboardComponents/NewCustomer';
import Customers from './DashboardComponents/Customers';
import Dashboard from './DashboardComponents/Dashboard';
import NewInvoice from './DashboardComponents/NewInvoice';
import Passwordreset from './LoginComponents/Passwordreset';
import Products from './DashboardComponents/Products';
import Profile from './DashboardComponents/Profile';
import Editprofile from './DashboardComponents/Editprofile';


function App() {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  return (
    <div className="App" >
      {/* <Layout /> */}
      <Router>
        <Routes>
        <Route path="/Login" element={<Login/>} /> 
        <Route path="/Signin" element={<Signin/>} /> 
        <Route path="/EnterOtp" element={<EnterOtp/>} />
        <Route path="/ForgotPassword" element={<ForgotPassword/>} />
        <Route path="/Reenterpassword" element={<Reenterpassword/>} />
        <Route path="/Passwordreset" element={<Passwordreset/>} />
        
        <Route
          exact
          path="/"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Dashboard/>
              
            </>
          }
        />
        <Route
          exact
          path="/Products"
          element={
            <>
              {" "}

              <Sidebar
              
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Products/>
              
            </>
          }
        />
         <Route
          exact
          path="/Profile"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Profile/>
             
            </>
          }
        />
         <Route
          exact
          path="/NewEstimates"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <NewEstimates/>
             
            </>
          }
        />
         <Route
          exact
          path="/Estimates"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Estimates/>
             
            </>
          }
        />
         <Route
          exact
          path="/Invoice"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Invoice/>
             
            </>
          }
        />
         
         <Route
          exact
          path="/NewInvoice"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <NewInvoice/>
              
            </>
          }
        />
         <Route
          exact
          path="/NewCustomer"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <NewCustomer/>
              
            </>
          }
        />
         
          <Route
          exact
          path="/Customers"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Customers/>
              
            </>
          }
        />
         <Route
          exact
          path="/Editprofile"
          element={
            <>
              {" "}

              <Sidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />
              <Navbar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />

              <Editprofile/>
             
            </>
          }
        />
         
      </Routes>
      </Router>

    </div>
  );
}

export default App;


